declare global {
  interface Window {
    // Used by <setup-google-ads> as an easy way to communicate if script loading failed
    googletagLoadFailed?: boolean;
  }
}

export const GOOGLE_TAG_LOAD_FAILED_EVENT = "googletagLoadFailed";

const DETECTED_EVENT = "adblock-detected";

type Reason = "gpt-load-failed" | "fake-googletag";

let adblockDetectReason: Reason | undefined = undefined;

/**
 * EventTarget that can be used to subscribe to adblock detection events.
 */
const target = new EventTarget();
function dispatchAdBlockerDetectedEvent(reason: Reason) {
  adblockDetectReason = reason;
  target.dispatchEvent(
    new CustomEvent<{ reason: Reason }>(DETECTED_EVENT, {
      detail: { reason },
    }),
  );
  return reason;
}

export const DetectAdBlock = {
  /**
   * Returns true if an adblocker has been detected, false otherwise. A value of false does not mean an
   * adblocker is not present, just that it hasn't been detected yet.
   */
  get adblockDetected(): boolean {
    return adblockDetectReason !== undefined;
  },

  initialize() {
    // Check whether the script failed to load
    // (see setup-google-ads)
    window.addEventListener(
      GOOGLE_TAG_LOAD_FAILED_EVENT,
      () => DetectAdBlock.onGptLoadFailed(),
      { once: true },
    );
    if (window.googletagLoadFailed) {
      DetectAdBlock.onGptLoadFailed();
    }

    window.googletag.cmd.push(() => {
      // Some adblockers load a fake version of googletag that runs commands but has noop
      // implementations of all the functions.
      if (googletag && googletag.getVersion && googletag.getVersion() == "") {
        // Some adblockers return an empty string as the version.
        dispatchAdBlockerDetectedEvent("fake-googletag");
        return;
      }

      // @ts-expect-error _loaded_ is an internal property and not defined on the googletag types,
      // but does exist.
      if (!googletag._loaded_) {
        dispatchAdBlockerDetectedEvent("fake-googletag");
        return;
      }
    });
  },

  /**
   *
   * Subscribes to adblock detection events. If an adblocker has previously been detected the provided
   * callback will be immediately executed.
   *
   * @param callback Function that will be called when the adblock is detected.
   * @returns A function that can be called to unsubscribe.
   */
  subscribe(callback: () => unknown) {
    if (adblockDetectReason) {
      // Immediately trigger the callback if we've already detected an adblock.
      callback();
    }
    target.addEventListener(DETECTED_EVENT, callback);
    return () => target.removeEventListener(DETECTED_EVENT, callback);
  },

  /**
   * Call this function when the GPT library failed to load.
   *
   * @example <script src="https://www.googletagservices.com/tag/js/gpt.js" onerror=() => DetectAdBlock.onGptLoadFailed()></script>
   */
  onGptLoadFailed() {
    dispatchAdBlockerDetectedEvent("gpt-load-failed");
  },
};
